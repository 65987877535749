import '@rainbow-me/rainbowkit/styles.css'
import { DefaultOptions, QueryClient } from '@tanstack/react-query'
import { Chain, ChainProviderFn, configureChains, createClient } from 'wagmi'
import { goerli, localhost, mainnet, sepolia } from 'wagmi/chains'
import { infuraProvider } from 'wagmi/providers/infura'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

import { makePersistent } from '@app/utils/persist'

import { WC_PROJECT_ID } from './constants'
import { getDefaultWallets } from './getDefaultWallets'

const jibchain = {
  id: 8899,
  network: 'JIBCHAIN L1',
  name: 'Jibchain',
  nativeCurrency: {
    name: 'JBC',
    symbol: 'JBC',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://jib-rpc.inan.in.th'],
    },
    public: {
      http: ['https://jib-rpc.inan.in.th'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Blockscout',
      url: 'https://exp-l1.jibchain.net/',
    },
  },
  contracts: {
    ensRegistry: {
      address: '0x87e31EbcAF0129C281b357E956660E057D36a39F',
    },
    ensUniversalResolver: {
      address: '0x80996350689A851016f83F112038Fb8Bac867d08',
      blockCreated: 2479788,
    },
    multicall3: {
      address: '0xc0C8C486D1466C57Efe13C2bf000d4c56F47CBdC',
      blockCreated: 2299048,
    },
  },
  testnet: false,
} as Chain

const providerArray: ChainProviderFn<
  typeof mainnet | typeof goerli | typeof localhost | typeof sepolia | typeof jibchain
>[] = []

// if (process.env.NEXT_PUBLIC_PROVIDER) {
// for local testing
providerArray.push(
  jsonRpcProvider({
    rpc: () => ({ http: process.env.NEXT_PUBLIC_PROVIDER || 'https://rpc-l1.jibchain.net' }),
  }),
)
// } else {
// if (!process.env.NEXT_PUBLIC_IPFS) {
//   // only use infura if we are not using IPFS
//   // since we don't want to allow all domains to access infura
//   providerArray.push(
//     infuraProvider({
//       apiKey: process.env.NEXT_PUBLIC_INFURA_KEY || 'cfa6ae2501cc4354a74e20432507317c',
//     }),
//   )
// }
// // fallback cloudflare gateway if infura is down or for IPFS
// providerArray.push(
//   jsonRpcProvider({
//     rpc: (c) => ({
//       http: `https://web3.ens.domains/v1/${c.network === 'homestead' ? 'mainnet' : c.network}`,
//     }),
//   }),
// )
// }

const { provider, chains } = configureChains(
  [jibchain],
  // [mainnet, goerli, localhost, sepolia, jibchain],
  providerArray,
)

const connectors = getDefaultWallets({
  appName: 'ENS',
  projectId: WC_PROJECT_ID,
  chains,
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 0,
    },
  },
})

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  queryClient,
  persister: null,
})

makePersistent(queryClient)

export const refetchOptions: DefaultOptions<unknown> = {
  queries: {
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 120,
    meta: {
      isRefetchQuery: true,
    },
    refetchOnMount: 'always',
  },
}

const queryClientWithRefetch = new QueryClient({
  queryCache: queryClient.getQueryCache(),
  defaultOptions: refetchOptions,
  mutationCache: queryClient.getMutationCache(),
})

const wagmiClientWithRefetch = createClient({
  autoConnect: true,
  connectors,
  provider,
  queryClient: queryClientWithRefetch,
  persister: null,
})

export { chains, queryClient, wagmiClient, wagmiClientWithRefetch }
