/* eslint-disable no-nested-ternary */
import { mq } from 'jns-thorin'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useErrorBoundary, withErrorBoundary } from 'react-use-error-boundary'
import styled, { css } from 'styled-components'
import { useNetwork, useSwitchNetwork } from 'wagmi'

import FeedbackSVG from '@app/assets/Feedback.svg'
// import { useIntercom } from 'react-use-intercom'
import TwitterIcon from '@app/assets/icons/brand-x.svg'
import GitHubIcon from '@app/assets/icons/github.svg'
import ErrorScreen from '@app/components/@atoms/ErrorScreen'

import { Navigation } from './Navigation'

const Container = styled.div(
  ({ theme }) => css`
    --padding-size: ${theme.space['4']};
    padding: var(--padding-size);
    display: flex;
    flex-gap: ${theme.space['4']};
    gap: ${theme.space['4']};
    flex-direction: column;
    align-items: stretch;
    @supports (-webkit-touch-callout: none) {
      // hack for iOS/iPadOS Safari
      // width should always be 100% - total padding
      width: calc(100% - calc(var(--padding-size) * 2));
      box-sizing: content-box;
    }
    ${mq.sm.min(css`
      --padding-size: ${theme.space['8']};
      gap: ${theme.space['6']};
      flex-gap: ${theme.space['6']};
    `)}
  `,
)

const ContentWrapper = styled.div(
  ({ theme }) => css`
    max-width: ${theme.space['192']};
    width: 100%;
    align-self: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: ${theme.space['4']};
    flex-gap: ${theme.space['4']};
  `,
)

const BottomPlaceholder = styled.div(
  ({ theme }) => css`
    height: ${theme.space['14']};
    ${mq.sm.min(
      css`
        height: ${theme.space['12']};
      `,
    )}
  `,
)

export const StyledFeedbackSVG = styled(FeedbackSVG)(() => css``)

export const Basic = withErrorBoundary(
  ({ children, noContentWrapper }: { children: React.ReactNode; noContentWrapper?: boolean }) => {
    const { chain: currentChain } = useNetwork()
    const { switchNetwork } = useSwitchNetwork()
    const router = useRouter()
    const [error] = useErrorBoundary()
    // const { boot } = useIntercom()

    // useEffect(() => {
    //   // Do not initialise with uid and email without implementing identity verification first
    //   boot()
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    useEffect(() => {
      if (currentChain && currentChain?.id !== 8899) {
        switchNetwork?.(8899)
        router.push('/unsupportedNetwork')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentChain?.id, router.pathname])

    return (
      <Container className="min-safe bg-mainGreyDark">
        <Navigation />
        {noContentWrapper ? (
          error ? (
            <ErrorScreen errorType="application-error" />
          ) : (
            children
          )
        ) : (
          <ContentWrapper>
            {error ? <ErrorScreen errorType="application-error" /> : children}
          </ContentWrapper>
        )}
        <BottomPlaceholder />
        <footer className="flex flex-row justify-between px-6 md:px-10 text-xs md:text-base items-center">
          <span>{`Copyright © ${new Date().getFullYear()} JibID. All rights reserved.`}</span>
          <div className="flex flex-row justify-center gap-1">
            <a
              className=" h-5 w-5 md:w-8 md:h-8 overflow-hidden flex items-center justify-center"
              href="https://github.com/JIBID-JNS"
              target="_blank"
              rel="noreferrer"
            >
              <GitHubIcon />
            </a>
            <a
              className=" w-5 h-5 md:w-8 md:h-8 overflow-hidden flex items-center justify-center"
              href="https://twitter.com/JIBID_JNS"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon />
            </a>
          </div>
        </footer>
      </Container>
    )
  },
)
